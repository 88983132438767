<template>
  <div class="form-group" style="width :100%">
    <div
      class="form-group-control" style="width :100%"
      :class="{
        'has-error': validates.firstWhere((error) => error.key == name) != null,
      }"
    >
      <div class="action" v-if="iconLeft">
        <img :src="iconLeft" />
      </div>
      <input
        :placeholder="label"
        :type="type || 'text'"
        :name="name"
        :label="label"
		:disabled ="disabled || false"
        :maxlength="maxlength"
        @keypress="key($event)"
        v-model.trim="object"
        :inputmode="inputmode || 'text'"
      />
      <div class="action" v-if="iconRight" @click="iconRigthClick">
        <img :src="iconRight" />
      </div>
    </div>
    <label
      class="has-error"
      v-if="errorMessage || validates.firstWhere((error) => error.key == name)"
    >
      {{
        errorMessage ||
        validates.firstWhere((error) => error.key == name).message ||
        validates.firstWhere((error) => error.key == name).value
      }}
    </label>
  </div>
</template>

<script>
export default {
  props: [
    "iconLeft",
    "iconRight",
    "errorMessage",
    "label",
    "name",
    "type",
    "model",
    "maxlength",
    "inputmode",
    "pattern",
	"disabled"
  ],
  inject: ["error"],
  data() {
    return {
      object: this.model,
    };
  },
  watch: {
    object(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    validates() {
      return this.error.validates;
    },
  },
  methods: {
    key(ev) {
      if (!this.pattern) return true;
      if (new RegExp(this.pattern).test(ev.key)) {
        return true;
      } else {
        ev.preventDefault();
      }
    },
    iconRigthClick() {
      this.$emit("actionRight");
    },
    iconLeftClick() {
      this.$emit("actionLeft");
    },
  },
};
</script>
<style scoped>
.has-error {
  color: #e35b47;
  margin: 0;
  font-size: 13px;
  margin-top: 4px;
  text-align: left;
}
.form-group-control.has-error {
  border: 1px solid #e35b47;
  margin: 0px;
}
</style>