<template>
  <div class="container" @keypress.enter="setupPassword()">
    <div class="title">
      <span>Xác thực thành công</span>
    </div>
    <div class="sub-title"></div>

    <label>Nhập mật khẩu mới</label>
    <input-view
      :iconLeft="require('@/assets/icons/lock.svg')"
      :iconRight="EyeNone"
      label="Mật khẩu gồm 8-32 ký tự"
      type="password"
      name="password"
      ref="passwordRef"
      @actionRight="viewPassword('passwordRef')"
      @input="password = $event"
    ></input-view>

    <div class="form-group"></div>

    <div class="form-group mb-0">
      <button class="btn btn-primary mt-0" @click.prevent="setupPassword()">Đổi mật khẩu</button>
    </div>
    <div style="min-height: 30px"></div>
  </div>
</template>
<script>
import InputView from "../../components/InputView.vue";
import EyeNone from "@/assets/icons/eye-none.svg";
import EyeOutline from "@/assets/icons/eye-outline.svg";
import Util from "@/util";

export default {
  components: { InputView },
  data() {
    return {
      EyeNone,
      EyeOutline,
      password: null,

      errorMessage: null,
      error: {
        message: null,
        validates: [],
      },
    };
  },
  provide() {
    return { error: this.error };
  },
  created() {
    this.cacheScreen = JSON.parse(window.localStorageX.getItem("cache_screen") || "{}");
  },
  methods: {
     async setupPassword() {
      try {
        this.error.validates = [];
        this.errorMessage = null

        this.$store.dispatch("loading");
        await this.$store.dispatch("auth/setupPassword", {
          code: this.cacheScreen.code,
          password: this.password,
        });
      } catch (e) {
        Util.errorHandler(this, e);
      }
      this.$store.dispatch("loading", false);
    },

    viewPassword(ref) {
      const props = this.$refs[ref].$props;
      if (props.type === "text") {
        props.type = "password";
        props.iconRight = this.EyeNone;
      } else {
        props.type = "text";
        props.iconRight = this.EyeOutline;
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.line-login-other {
  border-top: 1px solid rgba(43, 43, 43, 0.12);
  position: relative;
  text-align: center;
  margin-bottom: 27px;
}

.text {
  text-align: center;
  padding: 0 10px;
  top: -8px;
  position: absolute;
  background-color: white;
  font-size: 14px !important;
  color: #212121;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 180px;
}

.mb-0{
  margin-bottom: 0;
}
.mt-0{
  margin-top: 0;
}
</style>