function serialize(obj) {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj.map(i => serialize(i)))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj)
      .sort()
      .map(k => `${k}:${serialize(obj[k])}`)
      .join('|')
  }
  return obj
}

function digitNumber(number){
  if (number < 10) {
    return `0${number}`;
  }
  return number
}

function popupCenter({ url, w, h, close = () => {} }) {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(url, 'OAUTH2', `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`)

  if (window.focus) newWindow.focus();

  let timer = setInterval(function() {
    if (newWindow.closed) {
      clearInterval(timer);
      close()
    }
  }, 200);
}

function errorHandler(ctx, e) {
  if (e.error) {
    if(e.error.code == '109'){
      // exist phone
      return ctx.$store.dispatch('showAlert', {
        type: 'error',
        message: e.error.message,
        forceClose: true,
        actionClose:() => {
          ctx.$store.dispatch('hideAlert')
        },
        primaryText: 'Đăng nhập',
        actionPrimary: () => {
          ctx.$router.push(`/login?phone=${ctx.phone}`)
          ctx.$store.dispatch('hideAlert')
        }
      })
    }else if([403, 106, 404, 107, 105].includes(parseInt(e.error.code))){
      // block user
      return ctx.$store.dispatch('showAlert', {
        type: 'error',
        message: e.error.message,
        actionPrimary: () => {
          ctx.$router.push('/login')
          ctx.$store.dispatch('hideAlert')
        }
      })
    }else if([401].includes(parseInt(e.error.code))){
      // block user
      return ctx.$store.dispatch('showAlert', {
        type: 'error',
        message: e.error.message,
        primaryText: "Tải lại trang",
        actionPrimary: () => {
          location.reload()
        }
      })
    }
    else if (e.error.validates) {
      // validate
      ctx.error.validates =  e.error.validates
    } else {
      ctx.errorMessage =  e.error.message
    }
  } else {
    ctx.errorMessage =  e.message
  }
}


module.exports = {
  popupCenter,
  errorHandler,
  digitNumber,
  serialize
}